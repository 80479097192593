import React from "react";
import SidebarComponent from "../../components/SidebarComponent";

function mainExperiencesPage() {
  return (
    <div>
      <SidebarComponent />
      Experiences
    </div>
  );
}

export default mainExperiencesPage;
