import React from "react";
import SidebarComponent from "../../components/SidebarComponent";

function MainCertificatesPage() {
  return (
    <div>
      <SidebarComponent />
      Certificates
    </div>
  );
}

export default MainCertificatesPage;
