import React from "react";
import SidebarComponent from "../../components/SidebarComponent";

function MainProjectsPage() {
  return (
    <div>
      <SidebarComponent />
      Projects
    </div>
  );
}

export default MainProjectsPage;
