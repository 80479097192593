import React from "react";
import SidebarComponent from "../../components/SidebarComponent";

function MainMediaSosialPage() {
  return (
    <div>
      <SidebarComponent />
      Media Sosial
    </div>
  );
}

export default MainMediaSosialPage;
