import React from "react";
import SidebarComponent from "../../components/SidebarComponent";

function MainEducationsPage() {
  return (
    <div>
      <SidebarComponent />
      Educations
    </div>
  );
}

export default MainEducationsPage;
